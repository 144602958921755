import React from 'react';
import PropTypes from 'prop-types';

import { propTypes as linkPropTypes } from '../../../../01_atoms/Link';
import IconStat from '../../../../02_molecules/IconStat';
import FrayedContainer from '../../../../02_molecules/FrayedContainer';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../../utils/behaviorSettings';

// TODO: use CSS modules.
const BBCTASecondary = ({ title = '', contents, behaviorSettings = null, uuid = null }) => (
  <div
    className={`bb bb-cta-secondary ${generateClassNameByBehaviorSettings(behaviorSettings)}`}
    id={uuid}
  >
    <FrayedContainer title={title}>
      <div className="bb-cta-secondary-container">
        {contents.map((link, i) => (
          <IconStat
            key={`bb-other-ways-to-help-${i}` /* eslint-disable-line react/no-array-index-key */}
            iconURL={link.iconURL}
            title={link.cta}
            description={link.description}
            nextLink={link.nextLink}
            className="bb-cta-secondary--link"
          />
        ))}
      </div>
    </FrayedContainer>
  </div>
);

BBCTASecondary.propTypes = {
  title: PropTypes.string,
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      iconURL: PropTypes.string,
      cta: PropTypes.string,
      description: PropTypes.string,
      nextLink: PropTypes.shape(linkPropTypes),
    }),
  ).isRequired,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBCTASecondary;
